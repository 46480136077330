<template>
  <v-row class="p-3 pt-6">
    <v-col cols="12" md="6">
      <div class="h-55px">
        <v-text-field
          v-model="shipper_address.name"
          label="Shipper Name"
          clearable
          outlined
          dense
          :error-messages="nameErrors"
          @input="$v.shipper_address.name.$touch()"
          @blur="$v.shipper_address.name.$touch()"
        ></v-text-field>
      </div>
    </v-col>

    <v-col cols="12" md="6">
      <div class="h-55px">
        <VueTelInputVuetify
          class="ma-0 pa-0 mx-1"
          outlined
          v-model="shipper_address.phone"
          :inputOptions="{ showDialCode: false, tabindex: 0 }"
          mode="international"
          dense
          disabledFetchingCountry
          defaultCountry="AE"
          :error-messages="phoneErrors"
          @input="$v.shipper_address.phone.$touch()"
          @blur="$v.shipper_address.phone.$touch()"
        ></VueTelInputVuetify>
      </div>
    </v-col>

    <v-col cols="12">
      <div class="h-55px">
        <v-text-field
          v-model="shipper_address.shipper_address_company"
          label="Shipper Address Company"
          clearable
          outlined
          dense
          :error-messages="shipper_address_companyErrors"
          @input="$v.shipper_address.shipper_address_company.$touch()"
          @blur="$v.shipper_address.shipper_address_company.$touch()"
        ></v-text-field>
      </div>
    </v-col>

    <v-col cols="12" md="6">
      <div class="h-55px">
        <v-autocomplete
          @change="onCountryChange"
          v-model="shipper_address.country"
          label="Country"
          item-text="text"
          item-value="index"
          :items="countries"
          clearable
          outlined
          dense
          :error-messages="countryErrors"
          @input="$v.shipper_address.country.$touch()"
          @blur="$v.shipper_address.country.$touch()"
        ></v-autocomplete>
      </div>
    </v-col>

    <v-col cols="12" md="6">
      <div class="h-55px">
        <v-combobox
          v-model="shipper_address.state"
          :search-input.sync="state_text"
          :items="serverData?.states"
          :disabled="isStatesLoaded"
          :messages="oldValueText('state')"
          item-text="title"
          item-value="id"
          label="State"
          outlined
          required
          dense
          :error-messages="stateErrors"
          @input="$v.shipper_address.state.$touch()"
          @blur="$v.shipper_address.state.$touch()"
          @change="onStateChange"
          @keyup="updateValue('state')"
        >
          <template v-slot:append-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button
                    class="btn btn-info pl-2"
                    @click="
                      createNewItem(
                        'state',
                        shipper_address.country,
                        onCountryChange,
                        state_text
                      )
                    "
                  >
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
    </v-col>

    <v-col cols="12" md="6">
      <div class="h-55px">
        <v-combobox
          v-model="shipper_address.city"
          :search-input.sync="city_text"
          :items="serverData.cities"
          :disabled="isCitiesLoaded"
          :messages="oldValueText('city')"
          item-text="title"
          item-value="id"
          label="City"
          persistent-hint
          outlined
          dense
          :error-messages="cityErrors"
          @input="$v.shipper_address.city.$touch()"
          @blur="$v.shipper_address.city.$touch()"
          @change="onCityChange"
          @keyup="updateValue('city')"
        >
          <template v-slot:append-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button
                    class="btn btn-info pl-2"
                    @click="
                      createNewItem(
                        'city',
                        shipper_address.state.id,
                        onStateChange,
                        city_text
                      )
                    "
                  >
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
    </v-col>
    <!-- :messages="oldValueText('area')" -->
    <v-col cols="12" md="6">
      <div class="h-55px">
        <v-combobox
          :disabled="isAreasLoaded"
          v-model="shipper_address.area"
          :search-input.sync="area_text"
          :items="serverData.areas"
          :rules="[validationValueCheck('area')]"
          item-text="title"
          item-value="id"
          label="Area"
          persistent-hint
          outlined
          dense
          @keyup="updateValue('area')"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button
                    class="btn btn-info pl-2"
                    @click="
                      createNewItem(
                        'area',
                        shipper_address.city.id,
                        onCityChange,
                        area_text
                      )
                    "
                  >
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
    </v-col>

    <v-col cols="12" md="6">
      <div class="h-55px">
        <v-text-field
          v-model="shipper_address.address"
          label="Address 1"
          clearable
          outlined
          dense
          :error-messages="addressErrors"
          @input="$v.shipper_address.address.$touch()"
          @blur="$v.shipper_address.address.$touch()"
        ></v-text-field>
      </div>
    </v-col>

    <v-col cols="12" md="6">
      <div class="h-55px">
        <v-text-field
          v-model="shipper_address.address_2"
          label="Address 2"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
    </v-col>
    <CreateLocation
      :location-id="getLocationId"
      :location-type="getLocationType"
      :location-function="getLocationFunc"
      :locationSetValue="setTextValue"
      :initValue="getLocationInitValue"
      ref="location"
    ></CreateLocation>
  </v-row>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import CreateLocation from "./CreateLocation.vue";

export default {
  name: "ShipperAddressForm",
  mixins: [validationMixin],
  props: ["countries", "pageLoader", "data", "action"],
  components: { VueTelInputVuetify, CreateLocation },
  validations: {
    shipper_address: {
      name: { required },
      shipper_address_company: { required },
      phone: { required },
      country: { required },
      state: { required },
      city: { required },
      address: { required },
    },
  },
  data: () => ({
    state_text: null,
    city_text: null,
    area_text: null,

    location_type: null,
    locaiton_id: null,
    location_function: null,
    location_initValue: null,

    shipper_address: {
      name: null,
      shipper_address_company: null,
      phone: null,
      country: null,
      state: null,
      city: null,
      area: null,
      address: null,
      address_2: null,
    },

    old_address: {
      state: null,
      city: null,
      area: null,
    },

    serverData: { states: null, cities: null, areas: null },
  }),
  methods: {
    checkValidation() {
      this.$v.$touch();
    },
    resetData() {
      this.serverData = { states: null, cities: null, areas: null };
      this.shipper_address = {
        name: null,
        shipper_address_company: null,
        phone: null,
        country: null,
        state: null,
        city: null,
        area: null,
        address: null,
        address_2: null,
      };
      this.state_text = null;
      this.city_text = null;
      this.area_text = null;

      this.location_type = null;
      this.locaiton_id = null;
      this.location_function = null;
      this.location_initValue = null;
    },
    async getData() {
      this.pageLoader(true);
      const dump_copy = JSON.parse(JSON.stringify(this.data));
      this.shipper_address = {
        name: dump_copy.name,
        shipper_address_company: dump_copy.shipper_address_company,
        phone: dump_copy.phone,
        country: dump_copy.country,
        address: dump_copy.address,
        address_2: dump_copy.address_2,
      };
      await this.onCountryChange(this.data.country, true);
      await this.onStateChange(this.data.state, true);
      await this.onCityChange(this.data.city);
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.shipper_address[fieldName].$dirty) return errors;

      if ("required" in this.$v.shipper_address[fieldName]) {
        !this.$v.shipper_address[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    oldValueText(type) {
      let message = "";
      switch (type) {
        case "state":
          if (this.old_address.state) {
            message = `Received: ${this.old_address.state}`;
          }

          break;
        case "city":
          if (this.old_address.city) {
            message = `Received: ${this.old_address.city}`;
          }
          break;
        case "area":
          if (this.old_address.area) {
            message = `Received: ${this.old_address.area}`;
          }
          break;
      }
      return message;
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    validationValueCheck(type) {
      let validation = false;
      switch (type) {
        case "state":
          try {
            if (
              this.serverData?.states.filter(
                (state) => state.title == this.state_text
              ).length === 0
            ) {
              validation = "Please, select valid state!";
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "city":
          try {
            if (
              this.serverData.cities.filter(
                (city) => city.title == this.city_text
              ).length === 0
            ) {
              validation = "Please, select valid city!";
              this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "area":
          try {
            if (
              this.serverData.areas.filter(
                (area) => area.title == this.area_text
              ).length === 0
            ) {
              validation = "Please, select valid area!";
            } else {
              validation = true;
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "state":
          if (
            this.serverData?.states.filter(
              (state) => state.title == this.state_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.state_text = "";
              this.serverData.cities = null;
              this.serverData.areas = null;
            }, 1000);
          } else {
            this.onStateChange(
              this.serverData?.states.filter(
                (state) => state.title == this.state_text
              )[0]
            );
          }

          break;
        case "city":
          if (
            this.serverData?.cities.filter(
              (city) => city.title == this.city_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.city_text = "";
              this.serverData.areas = null;
            }, 1000);
          } else {
            this.onCityChange(
              this.serverData.cities.filter(
                (city) => city.title == this.city_text
              )[0]
            );
          }
          break;
        case "area":
          if (
            this.serverData?.areas.filter(
              (area) => area.title == this.area_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.area_text = "";
            }, 2000);
          } else {
            this.shipping_address.area = this.serverData?.areas.filter(
              (area) => area.title == this.area_text
            )[0].title;
          }
          break;
      }
    },
    async onCountryChange(val, initial = false) {
      this.pageLoader(true);
      try {
        const payload = { country: val };
        const { data } = await ApiService.post(
          "/address/states/search",
          payload
        );
        this.serverData.states = data.states;
        this.serverData.cities = null;
        this.serverData.areas = null;
        if (this.action === "edit") {
          this.shipper_address.state = this.serverData?.states.find(
            (item) => item.id === this.data.state
          );
        }
      } catch {
        //
      } finally {
        if (!initial) {
          this.pageLoader(false);
        }
      }
    },
    async onStateChange(val, initial = false) {
      this.pageLoader(true);
      try {
        const payload = {
          state: typeof val === "object" && val !== null ? val.id : val,
        };
        const { data } = await ApiService.post(
          "/address/cities/search",
          payload
        );
        this.serverData.cities = data.cities;
        this.serverData.areas = null;
        if (this.action === "edit") {
          this.shipper_address.city = this.serverData?.cities.find(
            (item) => item.id === this.data.city
          );
        }
      } catch {
        //
      } finally {
        if (!initial) {
          this.pageLoader(false);
        }
      }
    },
    async onCityChange(val) {
      this.pageLoader(true);
      try {
        const payload = {
          city: typeof val === "object" && val !== null ? val.id : val,
        };
        const { data } = await ApiService.post(
          "/address/areas/search",
          payload
        );
        this.serverData.areas = data.areas;
        if (this.action === "edit") {
          this.shipper_address.area = this.serverData?.areas.find(
            (item) => item.id === this.data.area
          );
        }
      } catch {
        //
      } finally {
        this.pageLoader(false);
      }
    },
    createNewItem(type, id, func, value = "") {
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    setTextValue(type, value = "") {
      switch (type) {
        case "state":
          this.state_text = value;
          if (value !== "") {
            this.shipper_address.state = this.serverData?.states.filter(
              (state) => state.title == this.state_text
            )[0];
            this.onStateChange(this.shipper_address.state);
          }

          break;
        case "city":
          this.city_text = value;
          if (value !== "") {
            this.formData.city = this.serverData?.cities.filter(
              (city) => city.title == this.city_text
            )[0];
            this.onCityChange(
              this.serverData?.cities.filter((city) => city.title == value)[0]
                .title
            );
          }

          break;
        case "area":
          this.area_text = value;

          if (value !== "") {
            try {
              this.formData.area = this.serverData?.areas.filter(
                (area) => area.title == this.area_text
              )[0];
            } catch {
              this.formData.area = null;
            }
          }

          break;
      }
    },
  },
  mounted() {
    if (this.data) {
      this.getData();
    }
  },
  computed: {
    nameErrors() {
      return this.handleFormValidation("name");
    },
    shipper_address_companyErrors() {
      return this.handleFormValidation("shipper_address_company");
    },
    phoneErrors() {
      return this.handleFormValidation("phone", this);
    },
    countryErrors() {
      return this.handleFormValidation("country", this);
    },
    stateErrors: function () {
      let error = this.handleFormValidation("state", this);
      let ms = this.validationValueCheck("state");
      let old = this.oldValueText("state");

      if (ms && error[0]) {
        // error.push(ms);
        if (old) {
          error[0] = ms + " " + error[0] + ". " + old;
        } else {
          error[0] = ms + " " + error[0];
        }
      } else if (ms) {
        if (old) {
          error[0] = ms + " " + old;
        } else {
          error[0] = ms + " ";
        }
      }
      return error;
    },
    cityErrors: function () {
      let error = this.handleFormValidation("city", this);
      let ms = this.validationValueCheck("city");
      let old = this.oldValueText("city");

      if (ms && error[0]) {
        // error.push(ms);
        if (old) {
          error[0] = ms + " " + error[0] + ". " + old;
        } else {
          error[0] = ms + " " + error[0];
        }
      } else if (ms) {
        if (old) {
          error[0] = ms + " " + old;
        } else {
          error[0] = ms + " ";
        }
      }
      return error;
    },
    addressErrors() {
      return this.handleFormValidation("address", this);
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
    isFormValid: function () {
      return !this.$v.$invalid;
    },
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
  },
};
</script>
