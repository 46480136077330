<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    max-width="1000px"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ $t("edit_client") }}</h3>
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <v-row>
          <v-col cols="12" md="3">
            <div class="h-55px">
              <v-select
                dense
                outlined
                clearable
                v-model="formData.type_id"
                :label="$t('client_type')"
                item-text="text"
                item-value="index"
                :items="serverData.client_types"
                :error-messages="typeErrors"
                @blur="$v.formData.type_id.$touch()"
              />
            </div>
          </v-col>

          <v-col cols="12" md="3">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.name"
                :label="$t('name')"
                :error-messages="nameErrors"
                @blur="$v.formData.name.$touch()"
              />
            </div>
          </v-col>

          <v-col cols="12" md="3">
            <v-file-input
              v-model="formData.logo"
              show-size
              counter
              multiple
              dense
              hide-details
              outlined
              clearable
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              :label="$t('logo')"
            />
          </v-col>

          <v-col cols="12" md="3">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.systemCode"
                :label="$t('system_code')"
                :error-messages="systemCodeErrors"
                @blur="$v.formData.systemCode.$touch()"
              />
            </div>
          </v-col>

          <v-col cols="12" md="3" v-if="organizationSystemCode !== 'SPL'">
            <div class="h-55px">
              <v-select
                dense
                outlined
                clearable
                v-model="formData.update_order_status"
                :label="$t('update_order_status')"
                item-text="text"
                item-value="index"
                :items="serverData.statuses"
              />
            </div>
          </v-col>

          <v-col cols="12" md="3" v-if="organizationSystemCode !== 'SPL'">
            <div class="h-55px">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.won_date"
                    :label="$t('won_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="formData.won_date"></v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col cols="12" md="3">
            <v-file-input
              v-model="formData.photo"
              show-size
              counter
              multiple
              dense
              hide-details
              outlined
              clearable
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              :label="$t('photo')"
            />
          </v-col>

          <v-col cols="12" md="3">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                type="number"
                @change="
                  () => validateMinValue('staff_account_limit', 0, 'formData')
                "
                v-model="formData.staff_account_limit"
                :label="$t('staff_account_limit')"
                :error-messages="accountLimitErrors"
                @blur="$v.formData.staff_account_limit.$touch()"
              />
            </div>
          </v-col>

          <v-col cols="12" :md="formData.type_id === 1 ? 3 : 4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.email"
                :label="$t('email')"
                :error-messages="emailErrors"
                @blur="$v.formData.email.$touch()"
              />
            </div>
          </v-col>

          <v-col
            v-if="organizationSystemCode !== 'SPL'"
            cols="12"
            :md="formData.type_id === 1 ? 3 : 4"
            :class="formData.type_id === 1 && 'px-0'"
          >
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.shopCode"
                :label="$t('shop_code')"
                :error-messages="shopCodeErrors"
                @blur="$v.formData.shopCode.$touch()"
              />
            </div>
          </v-col>

          <v-col
            v-if="organizationSystemCode !== 'SPL'"
            cols="12"
            :md="formData.type_id === 1 ? 3 : 4"
            :class="formData.type_id === 1 && 'pr-0'"
          >
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.shopName"
                :label="$t('shop_name')"
              />
            </div>
          </v-col>

          <v-col cols="12" md="3" v-if="formData.type_id === 1">
            <div class="h-55px">
              <v-select
                dense
                outlined
                clearable
                v-model="formData.aggregator_id"
                :label="$t('aggregator')"
                item-text="text"
                item-value="index"
                :items="serverData.aggregators"
              />
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-autocomplete
                v-model="formData.default_warehouse_id"
                :label="$t('default_warehouse')"
                :items="serverData.warehouses"
                item-text="text"
                item-value="index"
                dense
                outlined
                clearable
                hide-details
                hide-spin-buttons
              />
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-select
                dense
                outlined
                clearable
                v-model="formData.currency_id"
                :label="$t('currency')"
                item-text="text"
                item-value="index"
                :items="serverData.currencies"
              />
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                v-model="formData.shipping_label_name"
                :label="$t('shipping_label_name')"
                dense
                outlined
                clearable
              />
            </div>
          </v-col>

          <v-col cols="6" v-if="organizationSystemCode === 'SPL'">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.contract_number"
                :label="$t('contract_number')"
              />
            </div>
          </v-col>

          <v-col cols="6" v-if="organizationSystemCode !== 'SPL'">
            <div class="h-55px">
              <v-select
                dense
                outlined
                clearable
                v-model="formData.partial_allocation_handling"
                :label="$t('partial_allocation_handling_option')"
                item-text="text"
                item-value="index"
                :items="serverData.partial_allocation_handling_options"
              />
            </div>
          </v-col>

          <!--          <v-col cols="12" :md="organizationSystemCode !== 'SPL' ? 6 : 12">-->
          <v-col cols="6">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.description"
                :label="$t('description')"
              />
            </div>
          </v-col>

          <div
            class="col-12 col-sm-6 d-flex align-items-center justify-space-between my-0 py-0 pb-4"
          >
            <h5 class="mb-0 mt-0 pt-0 font-weight-regular">
              Shipper default details
            </h5>
            <v-switch
              v-model="formData.shipper_default_details"
              dense
              hide-details
              class="mt-0 pt-0"
              :true-value="1"
              :false-value="0"
            />
          </div>

          <v-expand-transition v-if="formData.shipper_default_details">
            <div
              class="col-12 ma-0 pa-0"
              v-show="formData.shipper_default_details"
            >
              <InputGroup label="Shipper Address">
                <ShipperAddressForm
                  ref="shipperAddress"
                  :countries="serverData.countries"
                  :pageLoader="pageLoader"
                  :data="serverData.customer.shipping_address"
                  action="edit"
                />
              </InputGroup>
            </div>
          </v-expand-transition>

          <div
            class="col-12 col-sm-6 d-flex align-center justify-space-between my-0 py-0 pb-4"
          >
            <h5 class="mb-0 mt-0 pt-0 font-weight-regular">
              Consignee default details
            </h5>
            <v-switch
              v-model="formData.consignee_default_details"
              dense
              hide-details
              class="mt-0 pt-0"
              :true-value="1"
              :false-value="0"
            />
          </div>
          <v-expand-transition v-if="formData.consignee_default_details">
            <div
              class="col-12 ma-0 pa-0"
              v-show="formData.consignee_default_details"
            >
              <InputGroup label="Consignee Address">
                <ConsigneeAddressForm
                  ref="consigneeAddress"
                  :countries="serverData.countries"
                  :pageLoader="pageLoader"
                  :data="serverData.customer.consignee_address"
                  action="edit"
                />
              </InputGroup>
            </div>
          </v-expand-transition>

          <v-col
            v-if="organizationSystemCode === 'SPL' && !isClient"
            cols="12"
            class="mb-3 mt-n8 mb-8"
          >
            <div class="h-55px">
              <v-radio-group
                :label="$t('spl_integration_type')"
                row
                v-model="formData.spl_integration_type"
              >
                <v-radio
                  v-for="item in serverData.spl_integration_types"
                  :key="item.index"
                  :label="$t(item.translation_key)"
                  :value="item.index"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
        </v-row>

        <v-card
          elevation="0"
          v-if="organizationSystemCode === 'SPL' && serverData.sales.length"
        >
          <v-toolbar-title
            class="h3 text-white text-center mt-4 pa-1"
            :style="{
              'background-color':
                $store.getters.currentUser.theme['text_color'],
            }"
            >{{ $t("sales") }}</v-toolbar-title
          >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                v-for="sale in serverData.sales"
                :key="sale.value"
              >
                <v-switch
                  class="mt-0 pt-0"
                  hide-details
                  v-model="formData.sales"
                  :color="$store.getters.currentUser.theme['text_color']"
                  :label="sale.text"
                  :value="sale.index"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card elevation="0" v-if="organizationSystemCode !== 'SPL'">
          <v-toolbar-title
            class="h3 text-white text-center mt-4 pa-1"
            :style="{
              'background-color':
                $store.getters.currentUser.theme['text_color'],
            }"
            >{{ $t("services") }}</v-toolbar-title
          >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                v-for="service in serverData.services"
                :key="service.value"
              >
                <v-switch
                  class="mt-0 pt-0"
                  hide-details
                  v-model="formData.services"
                  :color="$store.getters.currentUser.theme['text_color']"
                  :label="service.text"
                  :value="service.index"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <!--          <button-->
          <!--            type="reset"-->
          <!--            class="btn btn-light mr-3 px-5 py-3 ls1"-->
          <!--            @click="resetEditForm"-->
          <!--          >-->
          <!--            Clear-->
          <!--          </button>-->
          <button
            type="submit"
            class="btn btn--export-filter px-5 py-3 ls1"
            @click="submitEditForm"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import InputGroup from "@/own/components/InputGroup.vue";
import ShipperAddressForm from "./ShipperAddressForm.vue";
import ConsigneeAddressForm from "./ConsigneeAddressForm.vue";

export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  components: { InputGroup, ShipperAddressForm, ConsigneeAddressForm },
  validations() {
    return {
      formData: {
        systemCode: { required },
        name: { required },
        email: { required, email },
        type_id: { required },
        staff_account_limit: { required },
        shopCode: {
          required: requiredIf(() => {
            return this.organizationSystemCode !== "SPL";
          }),
        },
        // shipper_address: {
        //   required: requiredIf(() => this.formData.shipper_default_details),
        // },
        // consignee_address: {
        //   required: requiredIf(() => this.formData.consignee_default_details),
        // },
        // default_warehouse_id: {
        //   required: requiredIf(() => {
        //     return this.organizationSystemCode === "SPL";
        //   }),
        // },
      },
    };
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    menu: false,
    formData: {
      id: null,
      systemCode: null,
      update_order_status: null,
      won_date: null,
      shopCode: null,
      shopName: null,
      name: null,
      email: null,
      description: null,
      spl_integration_type: 0,
      photo: null,
      sales: [],
      services: [],
      type_id: null,
      aggregator_id: null,
      staff_account_limit: 5,
      default_warehouse_id: null,
      logo: null,
      currency_id: null,
      partial_allocation_handling: null,
      contract_number: null,
      shipper_default_details: 0,
      consignee_default_details: 0,
      shipper_address: null,
      consignee_address: null,
      shipping_label_name: null,
    },
  }),
  methods: {
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] &&
            item[0] !== "image" &&
            item[0] !== "photo" &&
            item[0] !== "logo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (
          item[1] &&
          (item[0] === "image" || item[0] === "photo" || item[0] === "logo")
        ) {
          data.append(item[0], item[1][0]);
        }
      });
      if (this.formData.shipper_default_details) {
        const shipper_address = {
          ...this.$refs.shipperAddress.shipper_address,
        };

        shipper_address.state = shipper_address.state.id;
        shipper_address.city = shipper_address.city.id;

        if (shipper_address.area) {
          shipper_address.area = shipper_address.area.id;
        }
        Object.keys(shipper_address).forEach((prop) => {
          if (shipper_address[prop]) {
            data.append(`shipper_address[${prop}]`, shipper_address[prop]);
          }
        });
      }

      if (this.formData.consignee_default_details) {
        const consigneeAddress = {
          ...this.$refs.consigneeAddress.shipper_address,
        };

        consigneeAddress.state = consigneeAddress.state.id;
        consigneeAddress.city = consigneeAddress.city.id;

        if (consigneeAddress.area) {
          consigneeAddress.area = consigneeAddress.area.id;
        }
        Object.keys(consigneeAddress).forEach((prop) => {
          if (consigneeAddress[prop]) {
            data.append(`consignee_address[${prop}]`, consigneeAddress[prop]);
          }
        });
      }

      return data;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/authorizations/clients/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = { ...this.serverData.customer };
          if (this.serverData.customer.spl_integration_type === null) {
            this.formData.spl_integration_type = 0;
          }
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.formData.shipper_default_details) {
        this.$refs.shipperAddress.$v.$touch();
        if (this.$refs.shipperAddress.$v.$invalid) {
          return;
        }
      }
      if (this.formData.consignee_default_details) {
        this.$refs.consigneeAddress.$v.$touch();
        if (this.$refs.consigneeAddress.$v.$invalid) {
          return;
        }
      }
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        const data = this.convertToFormData();
        ApiService.post("/authorizations/clients/update", data)
          .then(() => {
            SwalService.successMessage({
              title: this.$t("updated"),
              html: this.$t("client_updated_successfully"),
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.formData.consignee_default_details) {
        this.$refs.consigneeAddress.resetData();
      }
      if (this.formData.shipper_default_details) {
        this.$refs.shipperAddress.resetData();
      }
      this.formData = {
        id: null,
        systemCode: null,
        update_order_status: null,
        won_date: null,
        shopCode: null,
        shopName: null,
        name: null,
        email: null,
        description: null,
        spl_integration_type: 0,
        photo: null,
        sales: [],
        services: [],
        type_id: null,
        aggregator_id: null,
        staff_account_limit: 5,
        logo: null,
        contract_number: null,
        shipper_default_details: 0,
        consignee_default_details: 0,
        shipper_address: null,
        consignee_address: null,
        shipping_label_name: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    // organizationId() {
    //   return this.$store.getters.currentUser.data.organization_id;
    // },
    organizationSystemCode() {
      return this.$store.getters.organizationSystemCode;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    systemCodeErrors() {
      return this.handleFormValidation("systemCode", this);
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    accountLimitErrors() {
      return this.handleFormValidation("staff_account_limit", this);
    },
    typeErrors() {
      return this.handleFormValidation("type_id", this);
    },
    shopCodeErrors() {
      return this.handleFormValidation("shopCode", this);
    },
    emailErrors() {
      return this.handleFormValidation("email", this);
    },
    isClient: function () {
      return this.$store.getters.currentUser.data.is_client;
    },
    // defaultWarehouseIdErrors() {
    //   return this.handleFormValidation("default_warehouse_id", this);
    // },
  },
};
</script>
